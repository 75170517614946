import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import Login from 'components/login-auth';

@inject('mobxStore')
@observer
class LoginPage extends Component {
  static getInitialProps(req) {
    const { query } = req;
    const { redirect } = query;
    if (redirect === 'autorenew') {
      return {
        defaultTitleOverride: 'Sign in',
        subTitle: 'Get a magic link sent to your email that’ll sign you in instantly!',
      };
    }

    return {
      defaultTitleOverride: false,
    };
  }

  getRedirectUrl = () => {
    switch (this.props.mobxStore.country) {
      case 'ch':
        return '/ch/sunrise';
      case 'uk':
        return '/uk';
      case 'be':
        return '/be';
      case 'de':
        return '/de';
      default:
        return '/us/';
    }
  };

  render() {
    const {
      defaultTitleOverride,
      subTitle,
    } = this.props;

    const isAuthenticated = this.props.mobxStore.authStore.accessToken;

    return isAuthenticated ? (
      // Router.pushRoute('/dashboard')
      <></>
    ) : (
      <div id="loginPage">
        <Login
          defaultTitleOverride={defaultTitleOverride}
          subTitle={subTitle}
          redirectUrl={this.getRedirectUrl()}
        />
      </div>
    );
  }
}

export default LoginPage;
